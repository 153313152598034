body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pac-container {
  background-color: #fff;
  position: absolute!important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.3);
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 99999999999;
}

.k2k {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  overflow: hidden;
  padding: 32px;
  text-align: center;
  box-shadow: 0px 2px 1px -1px red 0px 1px 1px 0px red 0px 1px 3px 0px red !important;
}
